import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const snowattack1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Lehetőségünk nyílt lefejleszteni a fesztivál hivatalos weboldalát. Az alap informálás mellett meg kellett valósítanunk a jegyvásárlást, a szállásfoglalást valamint szállás csomagok és kiegészítő szolgáltatások foglalását is. Mindehhez pedig még egy promóter rendszert is.",
  displayXo: true,
  reverse: true,
  title: "Legyen hó!",
  picture: "/images/refs/snowattack/snowattack_logo.png",
  boldDescription: ''
}




export const snowattackInfoCards = [
  snowattack1,
]

