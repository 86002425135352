import { Reference } from "../reference-interface"
import { snowattackInfoCards } from "../../service-info-card-interface/used_by/snowattack-cards"

export const Snowattack : Reference = {
  monitorPic: "/images/refs/snowattack/snowattack_mac.png",
  siteName: "Snowattack",
  siteDescription: "Az Snowattack egy óriási fesztivál a téli sportok szerelmeseinek. Nekik fejlesztettük le hivatalos szállásfoglaló oldalukat.",
  siteUrl: "https://www.snowattack.hu/",
  cards: snowattackInfoCards,
  referencePics: [
    "/images/refs/snowattack/snowattack_screenshot_2.png",
    "/images/refs/snowattack/snowattack_card.png",
    "/images/refs/snowattack/snowattack_screenshot_1.png",
  ],
  pageFunctions: [
    "Modern design",
    "Szállásfoglalás",
  ],
  reversePageFunctions: false,
}
